<template>
  <div>
    <window-header></window-header>
    <section>
      <article style="height: 95px; width:200px">
        <span style="position:absolute;top:60px;left:50px;">Versión 1.0.0</span>
      </article>
    </section>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
export default {
  mixins: [WindowMixin],
  data: function() {
    return {
      title: "Versión"
    };
  }
};
</script>